import React from 'react';
import { PageProps } from 'src/utils/page-props';
import { ProtectedContent } from 'src/utils/protected-content';

interface PageContext {
	id: string;
	locale: string;
}

export default class PageTemplate extends React.Component<PageProps<never, PageContext>> {
	render() {
		const { id, locale } = this.props.pageContext;

		return (
			<>
				<ProtectedContent contentType="page" id={id} locale={locale} />
			</>
		);
	}
}
