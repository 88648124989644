import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Helmet } from 'react-helmet';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import ProtectedPage from './protected-page.graphql';
import styles from './styles.module.css';
import {
	SERVER_RESPONSE_NO_ACCESS,
	SERVER_RESPONSE_NO_ACCESS_TO_USER_TYPE,
} from 'src/utils/common';
import { useLoginManager } from 'src/utils/login-manager';
import { LoginForMore } from 'src/components/loginComponents/loginForMore';
import { DefaultLayoutTemplate } from 'src/pages/layout';
import { SectionContainer } from 'src/components/sectionContainer';
import { defaultRichTextOptions } from 'src/templates/utils/richTextOptions/defaultOptions';
import { curatedTitle } from 'src/utils/curated-title';
import { parse } from 'flatted';

interface Props {
	contentType: 'page';
	id: string;
	locale: string;
}

interface ContentfulPage {
	contentfulPage: {
		title: string;
		body: string;
		url: string;
	};
}

interface MessageProps {
	message: string;
}

const SimpleMessageContent = (props: MessageProps) => {
	return (
		<SectionContainer withBorderTop>
			<p className={styles.message}>{props.message}</p>
		</SectionContainer>
	);
};

export const ProtectedContent = ({ id, locale }: Props) => {
	const loginManager = useLoginManager();

	const { loading, error, data, refetch } = useQuery<ContentfulPage>(ProtectedPage, {
		variables: {
			id,
			locale,
		},
	});
	const notLoggedIn = error && error.message === SERVER_RESPONSE_NO_ACCESS;
	const notAuthorised = error && error.message === SERVER_RESPONSE_NO_ACCESS_TO_USER_TYPE;

	const getContent = () => {
		if (loading) {
			return <SimpleMessageContent message="Loading content, please wait" />;
		}
		if (error) {
			if (notLoggedIn) {
				if (loginManager.isLoggedIn) {
					refetch && refetch();
				}
				return <LoginForMore onRefetch={refetch} withSectionContainer />;
			} else if (notAuthorised) {
				return <SimpleMessageContent message="This content is not available" />;
			} else {
				return (
					<SimpleMessageContent message="There was a problem getting the resource, please try again later." />
				);
			}
		}

		return (
			<>
				<h1 className={styles.title}>{data?.contentfulPage?.title}</h1>
				{data?.contentfulPage?.body &&
					documentToReactComponents(parse(data?.contentfulPage?.body), defaultRichTextOptions)}
			</>
		);
	};

	return (
		<DefaultLayoutTemplate showLogin={notLoggedIn} onLoginSuccess={refetch}>
			<Helmet>
				<title>{curatedTitle(data?.contentfulPage?.title)}</title>
			</Helmet>
			<SectionContainer withBorderTop>{getContent()}</SectionContainer>
		</DefaultLayoutTemplate>
	);
};
